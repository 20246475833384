import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

import default_img from 'assets/images/default_img.svg'
import calendar from 'assets/images/calendar.svg'
import phone from 'assets/images/phone.svg'
import email from 'assets/images/email.svg'
import location from 'assets/images/location.svg'
import { Grid } from "@mui/material";
import {BaseModal} from "../../components/BaseModal";
import {ConfirmModal} from "../../components/ConfirmModal";

export const DriverDetailsModal = (props) => {
    const { open, handleClose, driver } = props
    const [openDriverPhoto, setOpenDriverPhoto] = useState(false)
    const [driverPhoto, setDriverPhoto] = useState(default_img)
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [actionType, setActionType] = useState(null);

    const handleConfirmModal = (action) => {
        setActionType(action)
        setOpenConfirmation(true)

    }
    const handleConfirmAction = () => {
        if(actionType === 'APPROVE'){
            console.log('You approved')
        } else {
            console.log('You rejected')
        }
        setOpenConfirmation(false)
        handleClose()
    }
    const handleShowDrivePhotoModal = (photo) => {
        setDriverPhoto(photo)
        setOpenDriverPhoto(true)
    }
    const Driver = [
        {
            title: 'Full Name',
            value: driver?.user.name,
            image: driver?.user.profile_picture,
            zoom: true,
            width: 50,
            height: 40
        },
        {
            title: 'Email Address',
            value: driver?.user.email,
            image: email,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'Physical Address',
            value: driver?.physical_address,
            image: location,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'Phone Number',
            value: driver?.user.phone_number,
            image: phone,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'Date of Birth',
            value: driver?.user.date_of_birth,
            image: calendar,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'National ID',
            value: driver?.national_id,
            image: driver?.national_id_file,
            zoom: true,
            width: 50,
            height: 45
        },
        {
            title: 'Driver License',
            value: driver?.driver_license,
            image: driver?.driver_license_file,
            zoom: true,
            width: 50,
            height: 45
        },
        {
            title: 'License Plate',
            value: driver?.licence_plate,
            image: driver?.licence_plate_file,
            zoom: true,
            width: 50,
            height: 45
        },
        {
            title: 'Insurance',
            value: driver?.insurance,
            image: driver?.insurance_file,
            zoom: true,
            width: 50,
            height: 45
        },

    ]

    return (
        <MDDialog
            open={open}
            handleClose={handleClose}
            title='Driver Details'
            paddingTop={10}
            maxWidth={'900px'}
        >
            <Grid container spacing={2}>
                {Driver.map((item, index) => (
                      <Grid item xs={12} md={6} key={`id-${index}`}>
                          <MDTypography variant={'h6'}>{item.title}</MDTypography>
                          <MDBox display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
                              <MDBox border={'1px solid red'} p={1} sx={{ overflowX:'auto', borderRadius: 2, width: '85%', }}>
                                  <MDTypography variant={'subtitle2'}>{item.value}</MDTypography>
                              </MDBox>
                                <MDBox borderRadius={'xxl'} width={item.width} height={item.height}>
                                    <MDBox
                                      component={'img'}
                                      alt={'img'}
                                      src={item.image || default_img}
                                      onClick={() => item.zoom ? handleShowDrivePhotoModal(item.image) : null}
                                      sx={$imageStyle}
                                    />
                                </MDBox>
                          </MDBox>
                      </Grid>
                    )
                )}
              
            </Grid>
            <BaseModal open={openDriverPhoto} width={'400px'} height={'350px'} handleClose={() => setOpenDriverPhoto(false)}>
                <MDBox component={'img'} src={driverPhoto} alt={'photo'} sx={[$imageStyle, {objectFit: 'contain'}]}/>
            </BaseModal>
            <ConfirmModal
              handleClose={() => setOpenConfirmation(false)}
              open={openConfirmation}
              handleConfirm={handleConfirmAction}
              title={'Are you sure?'}
              subtitle={'Are you sure you want to proceed?'}
            />
        </MDDialog>
    )
}

const $imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover || contain',
    borderRadius: 2
}
