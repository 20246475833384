import MDBox from "../MDBox";
import {Form, Formik} from "formik";
import MDTypography from "../MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../MDInput";
import FormikInput from "../FormikInput";
import MDButton from "../MDButton";
import {Modal} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import * as Yup from "yup";

export const ConfirmModal = ({handleClose, open, handleConfirm, title, subtitle}) => (
  <Modal open={open} onClose={handleClose}>
    <MDBox sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: '#fff',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
      width: 400,
      boxShadow: 24,
    }}>
      <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "22px"}}>{title}</MDTypography>
      <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "16px"}}>{subtitle}</MDTypography>
      <MDBox pb={2} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
        <MDButton color={'primary'} onClick={() => {
          handleConfirm()
        }}>Confirm</MDButton>
        <MDButton variant={'outlined'} color={'primary'} onClick={()=>{
          handleClose()
        }}>Cancel</MDButton>
      </MDBox>
    </MDBox>
  </Modal>
)


