import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useState } from "react";
import { DRIVER_RESTAURANT_STATUS, useApi } from "../../services/helpers";
import { ConfirmModal } from "../../components/ConfirmModal";
import { dataTableModel, rowRender } from "./utils";
import { RestaurantDetailsModal } from "./restaurantDetails";
import { BaseModal } from "../../components/BaseModal";
import default_img from 'assets/images/default_img.svg'
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";

export const Restaurants = () => {
  const api = useApi()

  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(dataTableModel)
  const [pageSize, setPageSize] = useState(10)
  const [paginationData, setPaginationData] = useState({ counts: 0, itemsPerPage: 0, currentPage: 1 });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [restaurantPhoto, setRestaurantPhoto] = useState(default_img);
  const [openRestaurantPhoto, setOpenRestaurantPhoto] = useState(false);
  const [openShowRestoDetailsModal, setShowRestoDetailsModal] = useState(false);
  const [suspendConfirm, setSuspendConfirm] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  
  const getRestaurants = (search= "", page = 1, ordering = "") => {
    setLoading(true)
    api.getRestaurants({search, page, ordering, page_size:pageSize, status: `${DRIVER_RESTAURANT_STATUS.APPROVED},${DRIVER_RESTAURANT_STATUS.SUSPENDED}`}).handle({
      onSuccess: (result) => {
        const { count, end, start, results } = result?.data
        setTable({ ...dataTableModel, rows: rowRender(results, setSuspendConfirm, setOpenRestaurantPhoto, setRestaurantPhoto, setShowRestoDetailsModal, setSelectedRestaurant) });
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
          ordering,
        }))
      },
      errorMessage: 'Error getting restaurants',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const suspendRestaurant = () => {
    setLoading(true)
    api.suspendRestaurant(selectedRestaurant?.id).handle({
      onSuccess: () => {
        setSuspendConfirm(false)
      },
      errorMessage: 'Error suspending restaurant',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const unsuspendRestaurant = () => {
    setLoading(true)
    api.approveRestaurant(selectedRestaurant?.id).handle({
      onSuccess: () => {
        setSuspendConfirm(false)
      },
      errorMessage: 'Error reverting suspend restaurant',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }
  const handleConfirmAction = () => {
    setOpenConfirmation(false)
    // confirmAction(broadcast)
  }
  
  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    const {search, page} = getFilteredPage()
    getRestaurants(search,page);
  }, [searchQuery, suspendConfirm, pageSize])


  return (
    <AdminLayout title={'Restaurants'}>
      <MDBox width={{xs:'95%', md:'98%'}} >
        <SearchBar loading={loading} search={getRestaurants} setSearchQuery={setSearchQuery} />
        <MDBox mt={4}>
          <DataTable
            table={table}
            searchFunc={getRestaurants}
            searchQuery={""}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            onPageChange={page => {
              const {search} = getFilteredPage
              getRestaurants(search, page)
              setPaginationData(value => ({ ...value, currentPage: page }))
            }}
          />
        </MDBox>
        <BaseModal open={openRestaurantPhoto} width={'auto'} height={'500px'} handleClose={() => setOpenRestaurantPhoto(false)}>
          <MDBox component={'img'} src={restaurantPhoto} alt={'photo'} sx={imageStyle} />
        </BaseModal>
        <ConfirmModal
          handleClose={handleCloseConfirmation}
          open={openConfirmation}
          handleConfirm={handleConfirmAction}
          title={'Are you sure?'}
          subtitle={'Are you sure you want to proceed?'}
        />
        <ConfirmModal
          handleClose={() => setSuspendConfirm(false)}
          open={suspendConfirm}
          handleConfirm={() => selectedRestaurant?.status === DRIVER_RESTAURANT_STATUS.SUSPENDED ? unsuspendRestaurant() : suspendRestaurant()}
          title={'Are you sure?'}
          subtitle={`Are you sure you want to ${selectedRestaurant?.status === DRIVER_RESTAURANT_STATUS.SUSPENDED ? 'un' : ""}suspend this restaurant?`}
        />
        <RestaurantDetailsModal
          open={openShowRestoDetailsModal}
          handleClose={() => setShowRestoDetailsModal(false)}
          restaurant={selectedRestaurant}
        />
      </MDBox>
    </AdminLayout>
  );
}
const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: 2
}
