import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import React, { useEffect, useState } from "react";
import { DRIVER_RESTAURANT_STATUS, useApi } from "../../services/helpers";
import { BaseModal } from "../../components/BaseModal";
import default_img from 'assets/images/default_img.svg'

import { dataTableModel, rowRender } from "./utils";
import { DriverDetailsModal } from "./driverDetails";
import { ConfirmModal } from "../../components/ConfirmModal";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";

export const Drivers = () => {
  const api = useApi()

  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(dataTableModel)
  const [pageSize, setPageSize] = useState(10)
  const [paginationData, setPaginationData] = useState({ counts: 0, itemsPerPage: 0, currentPage: 1, ordering: "", });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDriverPhoto, setOpenDriverPhoto] = useState(false);
  const [driverPhoto, setDriverPhoto] = useState(default_img)
  const [suspendConfirm, setSuspendConfirm] = useState(false);
  const [ShowDriverDetailsModal, setShowDriverDetailsModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');


  const getDrivers = (search= "", page = 1, ordering = "") => {
    setLoading(true)
    api.getDrivers({search, page, ordering, page_size:pageSize, status: `${DRIVER_RESTAURANT_STATUS.APPROVED},${DRIVER_RESTAURANT_STATUS.SUSPENDED}`}).handle({
      onSuccess: (result) => {
        console.log("page and ordering:", result?.data)
        const {count, end, start, results} = result?.data
        setTable({ ...dataTableModel, rows: rowRender(results, setSuspendConfirm, setOpenDriverPhoto, setDriverPhoto, setShowDriverDetailsModal, setSelectedDriver) });
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
          ordering,
        }))
      },
      errorMessage: 'Error getting drivers',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const suspendDriver = () => {
    setLoading(true)
    api.suspendDriver(selectedDriver?.id).handle({
      onSuccess: () => {
        setSuspendConfirm(false)
      },
      errorMessage: 'Error suspending driver',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const unsuspendDriver = () => {
    setLoading(true)
    api.approveDriver(selectedDriver?.id).handle({
      onSuccess: () => {
        setSuspendConfirm(false)
      },
      errorMessage: 'Error unsuspending driver',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    })
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }
  const handleConfirmAction = () => {
    setOpenConfirmation(false)
  }
  
  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    const { search, page } = getFilteredPage()
    getDrivers( search, page );
  }, [searchQuery, suspendConfirm, pageSize])

  return (
    <AdminLayout title={'Drivers'}>
      <MDBox width={{ xs: '95%', md: '98%' }} >
        <SearchBar loading={loading} search={getDrivers} setSearchQuery={setSearchQuery} />
        <MDBox mt={4}>
          <DataTable
            table={table}
            searchFunc={getDrivers}
            searchQuery={""}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={paginationData.currentPage}
            numberOfItems={paginationData.counts}
            numberOfItemsPage={paginationData.itemsPerPage}
            start={paginationData.start}
            end={paginationData.end}
            onPageChange={page => {
              const {search} = getFilteredPage
              getDrivers(search, page)
              setPaginationData(value => ({...value, currentPage: page}))
            }}
          />
        </MDBox>
        <BaseModal open={openDriverPhoto} width={'auto'} height={'500px'} handleClose={() => setOpenDriverPhoto(false)}>
          <MDBox component={'img'} src={driverPhoto} alt={'photo'} sx={imageStyle} />
        </BaseModal>
        <ConfirmModal
          handleClose={handleCloseConfirmation}
          open={openConfirmation}
          handleConfirm={handleConfirmAction}
          title={'Are you sure?'}
          subtitle={'Are you sure you want to proceed?'}
        />
        <ConfirmModal
          handleClose={() => setSuspendConfirm(false)}
          open={suspendConfirm}
          handleConfirm={() => selectedDriver?.status === DRIVER_RESTAURANT_STATUS.SUSPENDED ? unsuspendDriver() : suspendDriver()}
          title={'Are you sure?'}
          subtitle={`Are you sure you want to ${selectedDriver?.status === DRIVER_RESTAURANT_STATUS.SUSPENDED ? 'un' : ""}suspend this driver?`}
        />
        <DriverDetailsModal
          open={ShowDriverDetailsModal}
          handleClose={() => setShowDriverDetailsModal(false)}
          driver={selectedDriver}
        />
      </MDBox>
    </AdminLayout>
  );
}
const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: 2
}

