
import React, { useEffect, useState } from 'react';
import { setLayout, useMaterialUIController } from "../../context";
import { useLocation } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

import Map, { GeolocateControl, Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import colors from 'assets/theme/base/colors';

import logo from 'assets/icons/logo.png';
import logo_text from 'assets/icons/logo_text.png'
import phone from 'assets/icons/phone.png';
import bicycle from 'assets/icons/bicycle.png';
import marker from 'assets/icons/marker.png';
import red_start from 'assets/icons/red_start.png';
import red_start_opaque from 'assets/icons/red_start_opaque.png';
import user from 'assets/icons/user.png';
import { useRef } from 'react';

import { useMapService } from 'services/map_service'
import { useApi, useIsMobile } from "services/helpers";
import moment from 'moment';

const MAPBOX_TOKEN = "pk.eyJ1IjoiY2hvcGNob3AzMzMiLCJhIjoiY2x0Nmhra2g4MGJncjJpcDN3b212cDllZiJ9.FibUkw5aUR7CWmahfNJnBg"

const layer = {
  'id': 'route',
  'type': 'line',
  'source': 'route',
  'layout': {
    'line-join': 'round',
    'line-cap': 'round'
  },
  'paint': {
    'line-color': 'red',
    'line-width': 8
  }
}

const TrackOrder = () => {
  const map = useRef();

  const api = useApi()
  const isMobile = useIsMobile();

  const [driverLocation, setDriverLocation] = useState(false)
  const [order, setOrder] = useState()
  const [route, setRoute] = useState([])

  const { getRoute } = useMapService(MAPBOX_TOKEN)

  const getOrderDetails = (id) => {
    api.getOrder(id).handle({
      onSuccess: (result) => {
        let values = { ...result.data }
        if (values?.client_latitude && values?.client_longitude) {
          values.client_location = [values?.client_latitude, values?.client_longitude]
        }
        if (values?.driver?.driver_locations?.[0]?.location) {
          let temp = values?.driver?.driver_locations?.[0]?.location.split('POINT ')[1]
          temp = temp.replace('(', '').replace(')', '')
          const d_coords = temp.split(' ')
          if (!driverLocation?.latitude && !order) {
            map.current.setCenter({ lat: d_coords[1], lng: d_coords[0] })
            map.current.setZoom(15)
          }
          setDriverLocation({ latitude: d_coords[1], longitude: d_coords[0] })
        }
        setOrder(values)
      },
      errorMessage: 'Error getting order details, please try again.',
    })
  }

  useEffect(() => {
    const temp = window.location.pathname.split('/')
    const id = temp?.[2] || 0
    setInterval(() => {
      getOrderDetails(id)
    }, 5000);

  }, [])

  useEffect(() => {
    if (driverLocation?.latitude && driverLocation?.longitude && order?.client_location?.[0] && order?.client_location?.[1]) {
      getRoute([order?.client_location?.[1], order?.client_location?.[0]], [driverLocation?.longitude, driverLocation?.latitude]).then((res) => {
        const { code, routes } = res.data

        if (code === "Ok") {
          const route1 = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: routes?.[0]?.geometry?.coordinates || []
            }
          }
          setRoute(route1)
        }
      })
    }
  }, [order, driverLocation])



  return <PageLayout>
    <Map
      ref={map}
      style={styles.map}
      mapboxAccessToken="pk.eyJ1IjoicmJhc3Rvc2NsZW1lbnRlIiwiYSI6ImNsa2s1a3BqNzB0ZWkzZnBpanNoMHkyM3UifQ.jgmZlXEwyAOYY1FcNfJgBw"
      initialViewState={{
        longitude: -100,
        latitude: 40,
        zoom: 3.5,
        // zoom: 0.1
      }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      {route &&
        <Source type="geojson" data={route}>
          <Layer {...layer} />
        </Source>
      }

      <GeolocateControl
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
      />
      {order?.client_location?.[0] &&
        <Marker longitude={order?.client_location?.[1]} latitude={order?.client_location?.[0]} anchor="bottom" >
          <img src={marker} alt='logo' style={styles.trackEstimationIcon} />
        </Marker>
      }
      {driverLocation &&
        <Marker longitude={driverLocation?.longitude} latitude={driverLocation?.latitude} anchor="bottom" >
          <img src={bicycle} alt='logo' style={styles.trackEstimationIcon} />
        </Marker>
      }
    </Map>

    <MDBox sx={styles.footer} className="footer">
      {!isMobile &&
        <MDBox sx={styles.logoContainer}>
          <img src={logo} alt='logo' style={styles.logo} />
          <img src={logo_text} alt='logo_text' style={styles.logoText} />
        </MDBox>
      }
      <div style={{width:'100%'}} className='BarWrapper'>
      <MDBox sx={styles.div} className="wr">
        <MDBox sx={styles.cardContainer} className="orderWrapper">
          <MDBox sx={styles.cardRight} className="cardRight">
            <MDBox sx={styles.driverContent}>
              <img src={order?.driver?.user?.profile_picture || user} alt='logo_text' style={styles.driverImage} />
              <MDBox sx={styles.driverNamePhone}>
                <MDTypography fontWeight='medium' style={styles.driveName}>{order?.driver?.user?.name}</MDTypography>
                <MDTypography fontWeight="light" style={styles.drivePhone}>
                  
                  <img src={phone} alt='logo_text' style={{ height: 12, width: 12, top: 1, marginRight: 5 }} />
                  <a href={`tel:${order?.driver?.user?.phone_number}`} style={{color: colors.red.main,fontWeight:'600'}}>{order?.driver?.user?.phone_number}</a>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox sx={styles.cardLeft} className="cardLeft">
            <MDTypography fontWeight='medium' style={styles.title}>Your order is on the way</MDTypography>
            <MDBox sx={styles.trackEstimationContainer}>
              <img src={bicycle} alt='logo' style={styles.trackEstimationIcon} />
              <MDBox sx={styles.trackEstimationTimeContainer}>
                <MDTypography mr={1} fontWeight="light" style={styles.trackTime}>{moment(order?.created).format('hh:mm A')}</MDTypography>
                <MDBox sx={{ height: '2px', background: colors.red.main, width: '10px' }} />
                <MDTypography ml={1} fontWeight="light" style={styles.trackTime}>{moment(order?.created).format('hh:mm A')}</MDTypography>
              </MDBox>
              <img src={marker} alt='logo' style={styles.trackEstimationIcon} />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      </div>

    </MDBox>

  </PageLayout>
};

export default TrackOrder;

const styles = {
  map: {
    width: "100%",
    height: "100%",
    minHeight: "83vh",
  },
  footer: {
    width: '100%',
    height: '17vh',
    display: 'flex'
  },
  logoContainer: {
    width: '15%',
    height: '17vh',
    background: colors.white.main,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center'
  },
  logo: {
    width: '15%',
    height: '12vh',
    bottom: '13vh',
    objectFit: 'contain',
    position: 'absolute',
    zIndex: 10
  },
  logoText: {
    width: '15%',
    height: '12vh',
    bottom: '1vh',
    objectFit: 'contain',
    position: 'absolute'
  },
  div: {
    width: '100%',
    height: '17vh',
    background: colors.red.main,
    // display: ''
  },
  cardContainer: {
    zIndex: 3,
    width: '100%',
    maxWidth: '700px',
    height: '17vh',
    bottom: '8vh',
    margin: 'auto',
    position: 'relative',
    borderRadius: '10px',
    background: colors.white.main,
    boxShadow: 24,
    display: 'flex',
    padding: '10px'
  },
  cardRight: {
    paddingLeft: '20px',
    width: '40%',
    height: '100%',
    alignItems: 'center',
    display: 'flex'
  },
  cardLeft: {
    width: '60%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  driverContent: {
    display: 'flex',
  },
  driverImage: {
    width: 90,
    height: 90,
    borderRadius: 45

  },
  driverNamePhone: {
    height: 90,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    justifyContent: 'center'
  },
  driveName: {
    color: colors.red.main,
    fontSize: 20,
  },
  drivePhone: {
    color: colors.black.main,
    fontSize: 16,
  },
  starsContainer: {
    height: 40,
    width: '80%',
    marginLeft: '10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: colors.red.main,
    fontSize: 24,
    height: '50%'
  },
  trackEstimationContainer: {
    height: 50,
    width: '90%',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'

  },
  trackEstimationIcon: {
    width: 54,
    height: 54,
    borderRadius: 27,
    boxShadow: 24,
  },
  trackEstimationTimeContainer: {

    height: 50,
    width: '100%',
    borderBottom: `2px solid ${colors.red.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  trackTime: {
    color: colors.black.main,
    fontSize: 20,
  }
}