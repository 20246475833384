import MDBox from "components/MDBox";
import {CircularProgress, Input} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import debounce from "lodash.debounce";

const CssTextField = styled(TextField)({
  '.MuiInputBase-root': {

    minWidth: '100%',
    height: "44px",
    borderRadius: "20px",
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "14px",
  },
  '& label.Mui-focused': {
    color: '#000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#282828',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#282828',
    },
    '&:hover fieldset': {
      borderColor: '#282828',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#282828',
    },
  },
  '.MuiOutlinedInput-input': {
    color: "#000"
  }
});

const SearchBar = ({search, loading, setSearchQuery}) => {
  const delayedSearch = debounce(setSearchQuery, 300);

  const searchFunc = (text = '') => {
    delayedSearch(text)
    if (search) {
      search(text)
    }
  }

  return (
      <MDBox mr={3} sx={{boxShadow: "0px 2px 8px -1px rgba(0, 0, 0, 0.25)", borderRadius: "40px", width:'100%', maxWidth: '480px',}}>
      <CssTextField
        fullWidth
        placeholder="Search"
        type="text"
        onChange={(evt) => searchFunc(evt?.target?.value)}
        size="small"
        InputProps={{
          endAdornment: loading === false ? <Search/> : <CircularProgress size={14} color="primary"/>,
        }}
      />
    </MDBox>
  )

}

export default SearchBar;
