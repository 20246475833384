import { useCallback, useState } from "react"
import { create } from "apisauce"


export const useMapService = (apikey) => {
    const [mapServiceApi] = useState(create({ headers: { Accept: "application/json" } }))


    const getRoute = useCallback((origin, destination) => {
        const coordinates = `${origin[0]},${origin[1]};${destination[0]},${destination[1]}`
        const response = mapServiceApi.get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}`,
            {
                geometries: "geojson",
                access_token: apikey,
                overview: "full",
                // annotations: "distance,duration",
            }
        )
        return response
    }, [mapServiceApi, apikey])

    const getRoute3p = useCallback((origin, mid, destination) => {
        const coordinates = `${origin[0]},${origin[1]};${mid[0]},${mid[1]};${destination[0]},${destination[1]}`
        const response = mapServiceApi.get(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}`,
            {
                geometries: "geojson",
                access_token: apikey,
                overview: "full",
                // annotations: "distance,duration",
            }
        )
        return response
    }, [mapServiceApi, apikey])


    function calculateBBox(geojsonLineString) {
        if (!geojsonLineString || geojsonLineString.type !== 'LineString') {
            throw new Error('Invalid GeoJSON LineString');
        }

        let coords = geojsonLineString.coordinates;
        let minX, minY, maxX, maxY;

        coords.forEach((point, index) => {
            let [lon, lat] = point;

            if (index === 0) {
                minX = maxX = lon;
                minY = maxY = lat;
            } else {
                if (lon < minX) minX = lon;
                if (lon > maxX) maxX = lon;
                if (lat < minY) minY = lat;
                if (lat > maxY) maxY = lat;
            }
        });

        // BBox format: [minX, minY, maxX, maxY]
        return [[minX, minY], [maxX, maxY]];
    }

    return { getRoute, getRoute3p, calculateBBox }
}
