
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { money_fmt_int } from "services/helpers";

function snakeCaseToCapital(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const HomeCard = ({ color, title, info, action, setTableTitle }) => {
  return (
    <Card sx={{ minWidth: { xs: '255px' } }}>
      <MDBox borderRadius={'10px 10px 0px 0px'} bgColor={color} display="flex" alignItems="center" justifyContent="space-between" pt={1} px={3} height="5rem" >
        <MDBox textAlign="right" >
          <MDTypography color='white' variant="h4">{snakeCaseToCapital(title)}</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={1} mt={3}>
        {Object.entries(info).map(([key, value]) => (
          <RowCard key={key} label={key} amount={value || 0} action={action} setTableTitle={setTableTitle} title={title} />
        ))}
      </MDBox>
    </Card>
  );
}

const RowCard = ({ label, amount, action, setTableTitle, title }) => {

  const iconStyle = { width: 50, minWidth: 50, height: 50, borderRadius: 8, margin: 5 }
  const handleTableView = () => {
    if (title === "new_users") action(label.slice(0, -1))
    else action(label)
    setTableTitle(`${snakeCaseToCapital(title)} - ${snakeCaseToCapital(label)}`)
  }
  return (
    <MDBox borderRadius={'10px 10px 0px 0px'} display="flex" alignItems="center"
      justifyContent="space-between" pt={1} px={2} height="5rem">
      <MDTypography component="p" variant="button" color="text" display="flex" flexDirection="column" mt={1}>
        <MDTypography
          component="span"
          variant="100"
          fontWeight="500"
        >
          {snakeCaseToCapital(label)}
        </MDTypography>
        <MDTypography
          component="span"
          fontWeight="bold"
          variant="h4"
          color={"#366B65"}
        >
          {title === "delivery_fee" ? money_fmt_int(amount) : amount}
        </MDTypography>
      </MDTypography>
      <MDButton
        size="large"
        color={'primary'}
        onClick={handleTableView}
        style={iconStyle}
      >
        <Icon className="material-icons-outlined" fontSize="large" style={{ color: 'white' }}>{'visibility'}</Icon>
      </MDButton>
    </MDBox>
  )
}

// Setting default values for the props of HomeCard
HomeCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the HomeCard
HomeCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.shape({
    color: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node,
};

export default HomeCard;
