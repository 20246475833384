import {Modal} from "@mui/material";
import MDBox from "../MDBox";

export const BaseModal = ({children, open, handleClose, height= '600px', width= '600px'}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        width,
        height,
      }}>
        {children}
      </MDBox>
    </Modal>
  )
}
