/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import TrackOrder from "./pages/track-order";
import OrderDetails from "./pages/order-details";
import Home from "./pages/home";
import { Configuration } from "./pages/configuration"
import { ROUTES } from "services/constants";
import { Drivers } from "./pages/drivers";
import { Application, HomeIcon, Restaurant, Users } from "./assets/theme/components/SvgIcons";
import { Moto } from "./assets/theme/components/SvgIcons";
import { Driver } from "./assets/theme/components/SvgIcons";
import { Restaurants } from "./pages/restaurants";
import { PendingDriversApplications } from "./pages/pending-drivers-applications";
import {PendingRestaurantsApplications} from "./pages/pending-restaurants-applications";
import Logout from "./pages/logout";

const routes = [

  {
    name: "Track Order",
    key: "track_order",
    route: ROUTES.TRACK_ORDER,
    component: <TrackOrder />,
  },
  {
    name: "Order Details",
    key: "order_details",
    route: ROUTES.ORDER_DETAILS,
    component: <OrderDetails />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Home",
    key: "home",
    route: ROUTES.HOME,
    component: <Home />,
    icon: <HomeIcon />,
  },
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Availiable Deliveries",
  //   key: "availiable_deliveries",
  //   route: ROUTES.AVAILIABLE_DELIVERIES,
  //   component: <Home />,
  //   icon: <Moto />
  // },
  {
    type: "collapse",
    noCollapse: true,
    name: "Restaurants",
    key: "restaurants",
    route: ROUTES.RESTAURANTS,
    component: <Restaurants />,
    icon: <Restaurant />
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Drivers",
    key: "drivers",
    route: ROUTES.DRIVERS,
    component: <Drivers />,
    icon: <Driver />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Drivers Applications",
    key: "pending_drivers_applications",
    route: ROUTES.PENDING_DRIVERS_APPLICATIONS,
    component: <PendingDriversApplications />,
    icon: <Application />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Restaurants Applications",
    key: "pending_restaurants_applications",
    route: ROUTES.PENDING_RESTAURANTS_APPLICATIONS,
    component: <PendingRestaurantsApplications />,
    icon: <Application />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Configuration",
    key: "config",
    route: ROUTES.CONFIGURATION,
    component: <Configuration />,
    icon: <Application />,
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Logout",
    key: "logout",
    route: ROUTES.LOGOUT,
    component: <Logout />,
    icon: <Users />,
  },

];

export default routes;
