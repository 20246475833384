
import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout";
import MDBox from "../../components/MDBox";
import Barcode from "../../components/Barcode";
import MDTypography from "../../components/MDTypography";
import colors from 'assets/theme/base/colors';

import logo from 'assets/icons/logo.png';
import logo_text from 'assets/icons/logo_text.png'
import phone from 'assets/icons/phone.png';
import red_start_opaque from 'assets/icons/red_start_opaque.png';
import red_start from 'assets/icons/red_start.png';
import line from 'assets/icons/line.png';
import reciept from 'assets/icons/reciept.png';
import user from 'assets/icons/user.png';

import { useRef } from 'react';

import { useApi, useIsMobile } from "services/helpers";
import moment from 'moment';


const driver = {
  id: 1,
  image: 'https://d1gymyavdvyjgt.cloudfront.net/drive/images/uploads/headers/ws_cropper/1_0x0_790x520_0x520_become_a_better_driver.jpg',
  full_name: 'Generic Name',
  phone: '+1 800 2223333',
  stars: 4.2,
  start_time: '3:27 PM',
  end_time: '4:27 PM',
}

const OrderDetails = () => {
  const websocketRef = useRef();

  const api = useApi()
  const isMobile = useIsMobile();

  const [orderProof, setOrderProof] = useState()
  const [order, setOrder] = useState()

  const convertRatingToBooleans = (rating) => {
    return Array.from({ length: 5 }, (_, i) => i < Math.floor(rating) || (i === Math.floor(rating) && rating % 1 >= 0.5));
  }

  const getOrderDetails = (id) => {
    api.getOrder(id).handle({
      onSuccess: (result) => {
        setOrder(result.data)
        if (result?.data?.order_proofs?.[1]) {
          const proof = result?.data?.order_proofs[1]
          setOrderProof(proof)
        }
      },
      errorMessage: 'Error getting order details, please try again.',
    })
  }

  useEffect(() => {
    const temp = window.location.pathname.split('/')
    const id = temp?.[2] || 0

    getOrderDetails(id)
  }, [])

  return <PageLayout>

    <MDBox sx={styles.footer}>
      {!isMobile &&
        <MDBox sx={styles.logoContainer}>
          <img src={logo} alt='logo' style={styles.logo} />
          <img src={logo_text} alt='logo_text' style={styles.logoText} />
        </MDBox>
      }
      <MDBox sx={styles.div}>
        <MDBox sx={styles.cardContainer}>

          <MDBox sx={styles.cardRight}>

            <MDTypography fontWeight='medium' mt={2} style={styles.orderTitle}>ORDER ID</MDTypography>
            <MDTypography fontWeight='light' style={styles.orderTitle}>#{order?.order_number}</MDTypography>

            <img src={line} alt='logo_text' style={{ height: '1px', width: '95%' }} />

            <MDBox style={styles.infoContainer}>
              <MDTypography style={styles.orderText}>Pick-up date</MDTypography>
              <MDTypography style={styles.orderText}>{moment(order?.created).format('DD/MM/Y')}</MDTypography>
            </MDBox>
            <MDBox style={styles.infoContainer}>
              <MDTypography style={styles.orderText}>Pick-up time</MDTypography>
              <MDTypography style={styles.orderText}>{moment(order?.created).format('hh:mm A')}</MDTypography>
            </MDBox>
            <MDBox style={styles.infoContainer}>
              <MDTypography style={styles.orderText}>Delivery address</MDTypography>
              <MDTypography style={styles.orderText}>{order?.client_address}</MDTypography>
            </MDBox>
            <MDBox style={styles.infoContainer}>
              <MDTypography style={styles.orderText}>Delivery price</MDTypography>
              <MDTypography style={styles.orderText}>${order?.delivery_fee}</MDTypography>
            </MDBox>

            <img src={line} alt='logo_text' style={{ height: '1px', width: '95%' }} />

            <MDBox style={styles.infoContainer}>
              <MDTypography fontWeight='medium' style={styles.orderDescriptionTitle}>Order’s descriptions</MDTypography>
            </MDBox>
            <MDBox style={styles.infoContainer}>
              <MDTypography style={styles.orderText}>{order?.note || 'S / N'}</MDTypography>
            </MDBox>

            <img src={line} alt='logo_text' style={{ height: '1px', width: '95%' }} />

            <MDBox style={styles.infoContainer}>
              <MDTypography fontWeight='medium' style={styles.orderDescriptionTitle}>Tip</MDTypography>
              <MDTypography style={styles.orderText}>${order?.tip_amount}</MDTypography>
            </MDBox>
            <MDBox style={styles.infoContainer}>
              <MDTypography fontWeight='medium' style={styles.orderDescriptionTitle}>Total</MDTypography>
              <MDTypography style={styles.orderText}>${order?.order_amount_total}</MDTypography>
            </MDBox>

            <img src={line} alt='logo_text' style={{ height: '1px', width: '95%' }} />

            <Barcode generatedValue={order?.order_number} />

          </MDBox>

          <MDBox sx={styles.cardLeft}>

            <img src={order?.driver?.user?.profile_picture || user} alt='logo_text' style={styles.driverImage} />

            <MDBox sx={styles.starsContainer}>
              {convertRatingToBooleans(4).map((val, key) => <React.Fragment key={key}>
                <img src={val ? red_start : red_start_opaque} alt='logo' style={{ width: 35, height: 35, objectFit: 'contain' }} />
              </React.Fragment>)}
            </MDBox>

            <MDTypography fontWeight='medium' style={styles.driveName}>{order?.driver?.user?.full_name || 'S / N'}</MDTypography>

            <MDTypography fontWeight='light' style={styles.restaurantLabel}>RESTAURANT</MDTypography>
            <MDTypography fontWeight='regular' style={styles.restaurantName}>{order?.restaurant?.name}</MDTypography>
            <MDTypography fontWeight="light" style={styles.drivePhone}>
              <img src={phone} alt='logo_text' style={{ height: 12, width: 12, top: 1, marginRight: 2 }} />
              {order?.driver?.phone}
            </MDTypography>

            <img
              src={orderProof?.proof || logo_text}
              alt='logo_text'
              style={{
                width: '80%',
                height: 150,
                borderRadius: 5,
                background: colors.grey[500],
              }}
            />

            <MDTypography fontWeight='light' style={styles.dateAndTimeLabel}>DATE AND TIME OF DELIVERY</MDTypography>
            <MDTypography fontWeight='light' style={styles.dateAndTime}>
              {`${moment(order?.updated).format('MM/DD/YYYY')} - ${moment(order?.updated).format('hh:mm A')}`}
            </MDTypography>

          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>

  </PageLayout>
};

export default OrderDetails;

const styles = {
  footer: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  logoContainer: {
    width: '15%',
    height: '100vh',
    background: colors.white.main,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '90%',
    height: '12vh',
    bottom: '13vh',
    objectFit: 'contain',

  },
  logoText: {
    width: '90%',
    height: '12vh',
    marginTop: '30px',
    objectFit: 'contain',
  },
  div: {
    width: '100%',
    height: '100vh',
    background: colors.red.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // display: ''
  },
  cardContainer: {
    zIndex: 3,
    width: '700px',
    height: '70vh',
    // bottom: '8vh',
    margin: 'auto',
    position: 'relative',
    borderRadius: '10px',
    background: colors.white.main,
    boxShadow: 24,
    display: 'flex',
    padding: '10px'
  },
  cardRight: {
    paddingLeft: '20px',
    width: '55%',
    height: '100%',
    backgroundImage: `url(${reciept})`,
    // backgroundRepeat: "no-repeat",
    backgroundSize: `130% 100%`,
  },
  cardLeft: {
    width: '45%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 5,
  },
  infoContainer: {
    width: '95%',
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '5%',
  },
  orderText: {
    color: colors.white.main,
    fontSize: 12,
  },
  orderTitle: {
    color: colors.white.main,
    fontSize: 24,
    textAlign: 'center'
  },
  orderDescriptionTitle: {
    color: colors.white.main,
    fontSize: 15,
    textAlign: 'center'
  },
  driverImage: {
    width: 90,
    height: 90,
    borderRadius: 45
  },
  driveName: {
    color: colors.red.main,
    fontSize: 20,
  },
  starsContainer: {
    height: 40,
    width: '60%',
    marginLeft: '10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  drivePhone: {
    color: colors.black.main,
    fontSize: 14,
    alignSelf: 'flex-start',
    marginLeft: 15
  },
  restaurantLabel: {
    color: colors.grey[500],
    fontSize: 16,
    alignSelf: 'flex-start',
    marginLeft: 15
  },
  restaurantName: {
    color: colors.black.main,
    fontSize: 30,
    alignSelf: 'flex-start',
    marginLeft: 15
  },
  dateAndTimeLabel: {
    color: colors.grey[500],
    fontSize: 12,
    alignSelf: 'flex-end',
    marginRight: 15
  },
  dateAndTime: {
    color: colors.black.main,
    fontSize: 12,
    alignSelf: 'flex-end',
    marginRight: 15
  },

}