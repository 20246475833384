
import React, { useEffect, useState } from 'react';

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HomeCard from "components/HomeCard";
import { useApi } from "services/helpers";
import AdminLayout from 'components/AdminLayout';
import { orderTableModel, renderTableRowOrders, renderTableRowUsers, userTableModel } from './utils';
import DataTable from "../../components/DataTable";

const Home = () => {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [cardsData, setCardsData] = useState(null)
  const [pageSize, setPageSize] = useState(10)
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [orderTable, setOrderTable] = useState(orderTableModel)
  const [userTable, setUserTable] = useState(userTableModel)
  const [tableTitle, setTableTitle] = useState("")
  const [searchQuery, setSearchQuery] = useState('');

  const getUsersOverview = (filter, page = 1) => {
    setLoading(true)
    api.getUsersOverview({ user_type: filter, page, page_size: pageSize }).handle({
      onSuccess: (result) => {
        const { count, end, start, results } = result?.data
        const tmp = { ...userTableModel }
        tmp.rows = results.map(e => renderTableRowUsers(e))
        setUserTable(tmp)
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
        }))
      },
      errorMessage: 'Error getting information',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const getOrdersOverview = (filter, page = 1, place) => {
    setLoading(true)
    api.getOrdersOverview({ period: filter, page, page_size: pageSize }).handle({
      onSuccess: (result) => {
        const { count, start, end, results } = result?.data
        const tmp = { ...orderTableModel }
        tmp.rows = results.map(e => renderTableRowOrders(e))
        setOrderTable(tmp)
        setPaginationData(value => ({
          ...value,
          counts: count,
          start,
          end,
          itemsPerPage: results?.length,
        }))
      },
      errorMessage: 'Error getting information',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const getCardsData = () => {
    setLoading(true)
    api.getOverview().handle({
      onSuccess: (result) => {
        const { data } = result
        setCardsData(data)
      },
      errorMessage: 'Error getting information',
      onError: err => {
        console.log(err)
      },
      onFinally: () => setLoading(false)
    }
    )
  }

  const getFilteredPage = () => {
    let filters = { user_type: searchQuery, period: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    getCardsData()
  }, [])

  useEffect(() => {
    const { user_type, period, page } = getFilteredPage()
    // console.log("the title is:", tableTitle)
    // console.log("the searchQuery and pageSize:", searchQuery, pageSize)
    if (tableTitle.includes("Users")) getUsersOverview(user_type, page)
    else getOrdersOverview(period, page, "useEffect")
  }, [searchQuery, pageSize])



  return (
    <AdminLayout title='Home' loading={loading}>
      <MDBox width={{ xs: '95%', md: '98%' }} >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDBox mt={1.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 35, lg: 15, xl: 25, xxl: 3 }} flexWrap='nowrap' overflow='auto'>
                  {cardsData && Object.entries(cardsData).map(([key, value]) => (
                    <Grid item xs={3} md={3} lg={3} key={key}>
                      <MDBox mb={1.5}>
                        <HomeCard
                          color={'#EE4137'}
                          iconColor="success"
                          icon={'add_box'}
                          title={key}
                          info={value}
                          action={setSearchQuery}  // this is only for the demo
                          setTableTitle={setTableTitle} // this is only for the demo
                        />
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {tableTitle}
              </MDTypography>
            </MDBox>
            {tableTitle &&
              <DataTable
                table={tableTitle.includes("Users") ? userTable : orderTable}
                searchFunc={tableTitle.includes("Users") ? getUsersOverview : getOrdersOverview}
                searchQuery={searchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={paginationData.currentPage}
                numberOfItems={paginationData.counts}
                numberOfItemsPage={paginationData.itemsPerPage}
                start={paginationData.start}
                end={paginationData.end}
                onPageChange={page => {
                  const { user_type, period } = getFilteredPage()
                  tableTitle.includes("Users") ? getUsersOverview(user_type, page) : getOrdersOverview(period, page, "onPageChange")
                  setPaginationData(value => ({ ...value, currentPage: page }))
                }}
              />
            }
          </Grid>
        </Grid>
      </MDBox>
    </AdminLayout>
  )
};

export default Home;
