import AdminLayout from "../../components/AdminLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import React, { useEffect, useState } from "react";
import { DRIVER_RESTAURANT_STATUS, useApi } from "../../services/helpers";
import { PaginationCustom } from "../../components/DataTableSimple/PaginationCustom";
import SearchBar from "../../components/SearchBar";
import { ConfirmModal } from "../../components/ConfirmModal";
import default_img from "../../assets/images/default_img.svg";
import { BaseModal } from "../../components/BaseModal";
import { PendingRestaurantApplicationDetails } from "./PendingRestaurantApplicationDetails";
import Grid from "@mui/material/Grid";

export const PendingRestaurantsApplications = () => {
  const api = useApi()

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [pendingApplications, setPendingApplications] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [paginationData, setPaginationData] = useState({ counts: 0, itemsPerPage: 0, start: 1, end: 5, currentPage: 1 });

  const getRestaurants = (search, page = 1) => {
    setLoading(true)
    api.getRestaurants({search, page_size: pageSize, status: DRIVER_RESTAURANT_STATUS.PENDING, page}).handle({
        onSuccess: (result) => {
          const {count, end, start, results} = result?.data
          setPendingApplications(results)
          setPaginationData(value => ({
            ...value,
            counts: count,
            start,
            end,
            itemsPerPage: results?.length,
          }))
        },
        errorMessage: 'Error getting restaurants',
        onError: err => {
          console.log(err)
        },
        onFinally: () => setLoading(false)
      }
    )
  }

  const approveApplication = (id) => {
    setLoading(true)
    api.approveRestaurant(id).handle({
      onSuccess: () => {
        getRestaurants();
        setPaginationData(value => ({ ...value, currentPage: 1 }))
      },
      errorMessage: 'Error getting pending applications',
      onFinally: () => setLoading(false)
    })
  }

  const rejectApplication = (id) => {
    setLoading(true)
    api.rejectRestaurant(id).handle({
      onSuccess: () => {
        getRestaurants();
        setPaginationData(value => ({ ...value, currentPage: 1 }))
      },
      errorMessage: 'Error getting pending applications',
      onFinally: () => setLoading(false)
    })
  }

  const getFilteredPage = () => {
    let filters = { search: searchQuery, page: 1 }
    setPaginationData(value => ({ ...value, currentPage: filters.page }))
    return filters
  }

  useEffect(() => {
    const { search, page } = getFilteredPage()
    getRestaurants(search, page);
  }, [searchQuery, pageSize])


  return (
    <AdminLayout title={'Pending Restaurants Applications'}>
      <MDBox width={{ xs: '95%', md: '98%' }} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
        <MDBox display={"flex"} width={'100%'}>
          <SearchBar loading={loading} search={getRestaurants} setSearchQuery={setSearchQuery} />
        </MDBox>
        <Grid container>
          {pendingApplications?.length
            ? pendingApplications.map((item, index) => (
              <Grid item>
                <MDBox width={"100%"}>
                  <PendingRestaurantApplicationDetails
                    data={item}
                    approveApplication={approveApplication}
                    rejectApplication={rejectApplication}
                  />
                </MDBox>
              </Grid>
            ))
            : <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No data found</MDTypography>
            </MDBox>
          }
        </Grid>
        <MDBox width={'100%'} height={80}>
          <PaginationCustom
            currentPage={paginationData.currentPage}
            totalCount={paginationData.counts}
            startPage={paginationData.start}
            endPage={paginationData.end}
            setPageSize={setPageSize}
            pageSize={pageSize}
            onPageChange={page => {
              const { search } = getFilteredPage()
              getRestaurants(search, page)
              setPaginationData(value => ({ ...value, currentPage: page }))
            }}
          />
        </MDBox>
      </MDBox>
    </AdminLayout>
  );
}