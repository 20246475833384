import React, { useState } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

import default_img from 'assets/images/default_img.svg'
import phone from 'assets/images/phone.svg'
import email from 'assets/images/email.svg'
import location from 'assets/images/location.svg'
import { Grid } from "@mui/material";
import {BaseModal} from "../../components/BaseModal";
import {ConfirmModal} from "../../components/ConfirmModal";

export const RestaurantDetailsModal = (props) => {
    const { open, handleClose, restaurant } = props
    const [openRestoPhoto, setOpenRestoPhoto] = useState(false)
    const [restoPhoto, setRestoPhoto] = useState(default_img)
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [actionType, setActionType] = useState(null);

    const handleConfirmModal = (action) => {
        setActionType(action)
        setOpenConfirmation(true)

    }
    const handleConfirmAction = () => {
        if(actionType === 'APPROVE'){
            console.log('You approved')
        } else {
            console.log('You rejected')
        }
        setOpenConfirmation(false)
        handleClose()
    }
    const handleShowRestoPhotoModal = (photo) => {
        setRestoPhoto(photo)
        setOpenRestoPhoto(true)
    }

    const Resto = [
        {
            title: "Restaurant's name",
            value: restaurant?.user.name,
            image: restaurant?.user.profile_picture,
            zoom: true,
            width: 50,
            height: 40
        },
        {
            title: 'Email Address',
            value: restaurant?.user.email,
            image: email,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'Physical Address',
            value: restaurant?.physical_address,
            image: location,
            zoom: false,
            width: 35,
            height: 30
        },
        {
            title: 'Phone Number',
            value: restaurant?.user.phone_number,
            image: phone,
            zoom: false,
            width: 35,
            height: 30
        },

    ]

    return (
        <MDDialog
            open={open}
            handleClose={handleClose}
            title='Restaurant Details'
            paddingTop={10}
            maxWidth={'900px'}
        >
            <Grid container spacing={2}>
                {Resto.map((item, index) => (
                      <Grid item xs={12} md={6} key={`id-${index}`}>
                          <MDTypography variant={'h6'}>{item.title}</MDTypography>
                          <MDBox display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
                              <MDBox border={'1px solid red'} p={1} sx={{ borderRadius: 2, width: '85%' }}>
                                  <MDTypography variant={'subtitle2'}>{item.value}</MDTypography>
                              </MDBox>
                              {item.image &&
                                <MDBox borderRadius={'xxl'} width={item.width} height={item.height}>
                                    <MDBox
                                      component={'img'}
                                      alt={'img'}
                                      src={item.image || default_img}
                                      onClick={() => item.zoom ? handleShowRestoPhotoModal(item.image) : null}
                                      sx={$imageStyle}
                                    />
                                </MDBox>
                              }

                          </MDBox>
                      </Grid>
                    )
                )}
            </Grid>
            <BaseModal open={openRestoPhoto} width={'400px'} height={'350px'} handleClose={() => setOpenRestoPhoto(false)}>
                <MDBox component={'img'} src={restoPhoto} alt={'photo'} sx={[$imageStyle, {objectFit: 'contain'}]}/>
            </BaseModal>
            <ConfirmModal
              handleClose={() => setOpenConfirmation(false)}
              open={openConfirmation}
              handleConfirm={handleConfirmAction}
              title={'Are you sure?'}
              subtitle={'Are you sure you want to proceed?'}
            />
        </MDDialog>
    )
}

const $imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 2
}
