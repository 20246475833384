/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate} from "react-router-dom";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Image
import IllustrationLayout from "../../components/IllustrationLayout";
import {observer} from "mobx-react";
import {showMessage, useApi} from "../../services/helpers";
import {useEffect, useState} from "react";

function SetNewPassword() {
  const api = useApi()
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const getUrls = window.location.href.split('activate-user/')[1].split('/')

  const activateAccount = () => {
    const data = {
      uidb64: getUrls[0],
      token: getUrls[1]
    }
    // api.activateAccount(data)
    //   .then((result) => {
    //     if (result.kind === "ok") {
    //       setSuccess(true)
    //       showMessage('Account verified successfully', 'success')
    //     } else if (result.kind === "bad-data") {
    //       setError(true)
    //       if (result.errors.token) {
    //         showMessage(result.errors.token)
    //       } else {
    //         showMessage(result.errors?.errors)
    //       }
    //     } else {
    //       setError(true)
    //       showMessage()
    //     }
    //   })
    //   .catch(err => {
    //     showMessage()
    //     setError(true)
    //   })
      // .finally(() => setTimeout(() => navigate(ROUTES.LOGIN), 4000))
  }

  useEffect(() => {
    activateAccount()
  }, [])

  return (
    <IllustrationLayout
      title="Email Verification"
      description=""
      // illustration={bgImage}
    >
      <MDBox>
        <MDTypography variant="h2" fontWeight="medium" mb={3}>
          {success && 'Account Verified'}
          {error && 'Error'}
        </MDTypography>
      </MDBox>
    </IllustrationLayout>
  );
}

export default observer(SetNewPassword);
