import Barcode from 'react-barcode';

const BarcodeComponent = ({ generatedValue }) => (
    <Barcode
        displayValue={false}
        value={generatedValue || ''}
        width={3}
        background='transparent'
        lineColor='white'
        marginRight={3}

    />
);

export default BarcodeComponent