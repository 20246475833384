import { Grid } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import location from "../../assets/images/location.svg";
import MDButton from "../../components/MDButton";
import React, { useState } from "react";
import default_img from "../../assets/images/default_img.svg";
import default_img2 from "../../assets/images/default_img2.svg";
import identification from "../../assets/images/identification.svg";
import driver_img from "../../assets/images/Driver.svg";
import license from "../../assets/images/License.svg";
import email from "../../assets/images/email.svg";
import phone_number from "../../assets/images/phone.svg";
import calendar from "../../assets/images/calendar.svg";
import { BaseModal } from "../../components/BaseModal";
import { ConfirmModal } from "../../components/ConfirmModal";

export const PendingDriverApplicationDetails = ({ data, approveApplication, rejectApplication }) => {
  const [driverPhoto, setDriverPhoto] = useState(default_img)
  const [approve, setApprove] = useState(null)
  const [openDriverPhoto, setOpenDriverPhoto] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false)
  }
  const handleConfirm = () => {
    approve ? approveApplication(data.id) : rejectApplication(data.id)
    setApprove(null)
    setOpenConfirmModal(false)
  }

  return (
    <MDBox marginLeft={2} maxWidth={'830px'}>
      <Grid container mt={2} spacing={1} key={`id-${data.id}`} sx={containerStyle}>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Full Name</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography  variant={'subtitle2'}>{data?.user.name}</MDTypography>
            </MDBox>
            <MDBox
              component={'img'}
              src={data?.user?.profile_picture || default_img2}
              alt={'image'}
              style={iconImage}
              onClick={() => { setDriverPhoto(data?.user.profile_picture); setOpenDriverPhoto(true) }}
            />

          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Email</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography sx={{ width: '100%', overflow: { xs: 'auto', md: 'hidden' }, textOverflow: { xs: '', md: 'ellipsis' }, }} variant={'subtitle2'}>{data?.user.email}</MDTypography>
            </MDBox>
            <MDBox component={'img'} src={email} alt={'image'} width={20} height={20} />

          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>National ID</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography variant={'subtitle2'}>{data?.national_id}</MDTypography>
              <MDBox
                component={'img'}
                src={data?.national_id_file || identification}
                alt={'image'}
                style={iconImage}
                onClick={() => { setDriverPhoto(data?.national_id_file); setOpenDriverPhoto(true) }}
              />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Physical Address</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography  variant={'subtitle2'}>{data?.physical_address}</MDTypography>
            </MDBox>
            <MDBox component={'img'} src={location} alt={'image'} width={20} height={20} />
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography  variant={'h6'}>Phone Number</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography  variant={'subtitle2'}>{data?.user.phone_number}</MDTypography>
            </MDBox>
            <MDBox component={'img'} src={phone_number} alt={'image'} width={20} height={20} />
          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Date of Birth</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography  variant={'subtitle2'}>{data?.user.date_of_birth}</MDTypography>
            </MDBox>
            <MDBox component={'img'} src={calendar} alt={'image'} width={20} height={20} />
          </MDBox>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Driver License</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography  variant={'subtitle2'}>{data?.driver_license}</MDTypography>
            </MDBox>
            <MDBox
              component={'img'}
              src={data?.driver_license_file || license}
              alt={'image'}
              style={iconImage}
              onClick={() => { setDriverPhoto(data?.driver_license_file); setOpenDriverPhoto(true) }}
            />

          </MDBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MDTypography variant={'h6'}>Insurance</MDTypography>
          <MDBox style={boxStyle}>
            <MDBox style={boxStyle2}>
              <MDTypography variant={'subtitle2'}>{data?.insurance}</MDTypography>
              <MDBox
                component={'img'}
                src={data?.insurance_file || driver_img}
                alt={'image'}
                style={iconImage}
                onClick={() => { setDriverPhoto(data?.insurance_file); setOpenDriverPhoto(true) }}
              />
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item container height={'74px'} spacing={1} xs={12} sm={6} md={4} direction={'row'} justifyContent={'flex-end'} alignItems={'flex-end'} marginY={{ xs: 3, md: '0' }}>
          <Grid item xs={12} sm={6} md={6}>
            <MDButton
              color={'primary'}
              type="submit"
              sx={{ width: '100%' }}
              onClick={() => { setOpenConfirmModal(true); setApprove(true) }}
            >
              Approve
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MDButton
              color={'primary'}
              type="button"
              variant="outlined"
              sx={{ width: '100%' }}
              onClick={() => { setOpenConfirmModal(true); setApprove(false) }}
            >
              Reject
            </MDButton>
          </Grid>
        </Grid>
        <BaseModal open={openDriverPhoto} width={'auto'} height={'60%'} handleClose={() => setOpenDriverPhoto(false)}>
          <MDBox component={'img'} src={driverPhoto} alt={'photo'} sx={imageStyle} />
        </BaseModal>
        <ConfirmModal
          handleClose={handleCloseConfirm}
          open={openConfirmModal}
          handleConfirm={handleConfirm}
          title={"Are you sure?"}
          subtitle={"Are you sure you want to proceed?"}
        />
      </Grid>
    </MDBox>
  )
}

const containerStyle = {
  background: 'white',
  borderRadius: 2,
  padding: 4,
  border: 1,
  boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.2)",
  alignItems: 'center',
  width: '100%'
}

const boxStyle2 = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: 0,
  overflow: 'auto',
  alignItems: 'center',
  minHeight: '40px',
  position: 'relative'

}
const boxStyle = {
  border: '1px solid red',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 3,
  padding: 5,
  minHeight: '40px',
  width: '100%',
}

const iconImage = {
  width: 25,
  height: 25,
  borderRadius: 3
}

const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: 2
}
