import { money_fmt } from "services/helpers";

export const dataTableModel = {
  columns: [
    { Header: "Id", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Data", accessor: "data" },
  ],
  rows: [
    { id: 1, name: 'Name 1', data: 'Data 1' },
    { id: 2, name: 'Name 2', data: 'Data 2' },
    { id: 3, name: 'Name 3', data: 'Data 3' },
    { id: 4, name: 'Name 4', data: 'Data 4' },
    { id: 5, name: 'Name 5', data: 'Data 5' },
    { id: 6, name: 'Name 6', data: 'Data 6' },
    { id: 7, name: 'Name 7', data: 'Data 7' },
    { id: 8, name: 'Name 8', data: 'Data 8' },
    { id: 9, name: 'Name 9', data: 'Data 9' },
    { id: 10, name: 'Name 10', data: 'Data 10' },
    { id: 11, name: 'Name 11', data: 'Data 11' },
    { id: 12, name: 'Name 12', data: 'Data 12' },
    { id: 13, name: 'Name 13', data: 'Data 13' },
    { id: 14, name: 'Name 14', data: 'Data 14' },
  ],
};

export const cardsInitialData = [
  {
    color: '#EE4137',
    icon: 'add_box',
    title: 'New Users',
    info: [
      {
        color: "#366B65",
        amount: 0,
        label: "Total",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: 0,
        label: "Drivers",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: 0,
        label: "Restaurants",
        icon: 'visibility',
      }
    ]
  },
  {
    color: '#EE4137',
    icon: 'add_box',
    title: 'Orders Quantity',
    info: [
      {
        color: "#366B65",
        amount: 0,
        label: "Total",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: 0,
        label: "Past Month",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: 0,
        label: "Current Month",
        icon: 'visibility',
      }
    ]
  },
  {
    color: '#EE4137',
    icon: 'add_box',
    title: 'Orders Amount',
    info: [
      {
        color: "#366B65",
        amount: '$0',
        label: "Total",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: '$0',
        label: "Past Month",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: '$0',
        label: "Current Month",
        icon: 'visibility',
      }
    ]
  },
  {
    color: '#EE4137',
    icon: 'add_box',
    title: 'Tip Amount',
    info: [
      {
        color: "#366B65",
        amount: '$0',
        label: "Total",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: '$0',
        label: "Past Month",
        icon: 'visibility',
      },
      {
        color: "#366B65",
        amount: '$0',
        label: "Current Month",
        icon: 'visibility',
      }
    ]
  },
]
export const userTableModel = {
  columns: [
    { Header: "Id", accessor: "id", disableOrdering: true, width: '50px' },
    { Header: "Name", accessor: "name", disableOrdering: true },
    { Header: "Email", accessor: "email", disableOrdering: true },
    { Header: "Phone", accessor: "phone_number", disableOrdering: true },
  ],
  rows: [],
};

export const orderTableModel = {
  columns: [
    { Header: "Number", accessor: "id", disableOrdering: true, },
    { Header: "Restaurant", accessor: "restaurant", disableOrdering: true },
    { Header: "Driver", accessor: "driver", disableOrdering: true },
    { Header: "Client", accessor: "client", disableOrdering: true },
    { Header: "Total Amount", accessor: "total", disableOrdering: true },
    { Header: "Delivery Fee", accessor: "tip", disableOrdering: true },
  ],
  rows: [],
};

export const renderTableRowUsers = (item) => {
  item.id = item.id ?? 'No data'
  item.name = item.name ?? 'No data'
  item.email = item.email ?? 'No data'
  item.phone_number = item.phone_number ?? 'No data'

  return item
}

export const renderTableRowOrders = (item) => {
  item.number = item.id ?? 'No data'
  item.restaurant = item?.restaurant?.user?.name ?? 'No data'
  item.driver = item?.driver?.user?.name ?? 'No data'
  item.client = item?.client_name ?? 'No data'
  item.total = item?.total_amount ? money_fmt(item?.total_amount) : 'No data'
  item.tip = item?.delivery_fee ? money_fmt(item?.delivery_fee) : 'No data'
  return item
}
