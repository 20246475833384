import MDBox from "../MDBox";
import {openInNewTab} from "../../services/helpers";
import React from "react";
import default_img from 'assets/images/default_img.svg'

export const linkRender = (link) => {
  return (
    <MDBox
      onClick={() => link ? openInNewTab(link) : {}}
      component={'img'}
      alt={'img'}
      src={link ? {uri: link} : default_img}
      sx={{
        cursor: 'pointer',
        objectFit: 'cover',
        height: 70,
        width: 70,
        borderRadius: 35
      }} />
  )
}
export const photoRender = (photo, setOpenPhoto, setPhoto) => {
  return (
    <MDBox
      onClick={() => {
        if (photo) {
          setOpenPhoto(true)
          setPhoto(photo)
        }
      }}
      component={'img'}
      alt={'img'}
      src={photo ? photo : default_img}
      sx={{
        cursor: 'pointer',
        objectFit: 'contain',
        height: 70,
        width: 70,
        borderRadius: 35
      }} />
  )
}
