import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { showMessage, useApi } from "../../services/helpers";


const PublicDriverProfile = () => {
  const api = useApi()
  const getUrls = window.location.href.split('public_driver_profile/')[1].split('/')

  const [driver, setDriver] = useState({});

  const activateAccount = () => {
    const id = getUrls[0]
    console.log(' data =>', id)
    api.getDriver(id).handle({
      onSuccess: (result) => setDriver(result.data),
      onError: err => {
        showMessage('Error getting information')
        console.log(err)
      },
    })
  }

  useEffect(() => {
    activateAccount()
  }, [])

  return <PageLayout>
    <MDBox style={{
      height: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    }} bgColor={'primary'}>
      <MDBox bgColor='#fff' mt={2} style={{ margin: 'auto', padding: 50, gap: 10, borderRadius: 10 }}>
        <MDTypography variant={'h2'} mb={3}>Driver Public Profile</MDTypography>
        <MDBox
          component={'img'}
          src={driver?.user?.profile_picture}
          alt={'image'}
          style={iconImage}
        />
        <MDBox display='flex' mt={2}>
          <MDTypography variant={'h4'} >Name:</MDTypography>
          <MDTypography variant={'h4'} style={{fontWeight: '500'}} ml={1}>{driver?.user?.name}</MDTypography>
        </MDBox>
        <MDBox display='flex' mt={2}>
          <MDTypography variant={'h4'} >Email:</MDTypography>
          <MDTypography variant={'h4'} style={{fontWeight: '500'}} ml={1}>{driver?.user?.email}</MDTypography>
        </MDBox>
        <MDBox display='flex' mt={2}>
          <MDTypography variant={'h4'} >Phone Number:</MDTypography>
          <MDTypography variant={'h4'} style={{fontWeight: '500'}} ml={1}>{driver?.user?.phone_number}</MDTypography>
        </MDBox>
        <MDBox display='flex' mt={2}>
          <MDTypography variant={'h4'} >Physical Address:</MDTypography>
          <MDTypography variant={'h4'} style={{fontWeight: '500'}} ml={1}>{driver?.physical_address}</MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  </PageLayout>
};

export default PublicDriverProfile;

const iconImage = {
  width: 300,
  height: 300,
  borderRadius: 150,
}
