import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import * as Types from "./api.types"
import {ApiBase, ApiReturnType} from "./api-base";
import {API_VERSION_PREFIX} from "../constants";
import {PMTaskType, VendorTaskType} from "../constants_ts";
import {SimplePostResult} from "./api.types";


/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config)
  }

  login(username: string, password: string): ApiReturnType<Types.SimplePostResult> {
    return this.simple_post(`${API_VERSION_PREFIX}/authenticate/?type=ADMIN`, {
      email: username,
      password,
    })
  }

  getOrders(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/order/`, data)
  }

  getDriver(id?: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/drivers/${id}/`)
  }

  getOrder(id: number) {
    return this.simple_get(`${API_VERSION_PREFIX}/orders/${id}/`)
  }

  getRestaurants(data?: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/restaurants/`, data)
  }

  getDrivers(data?: any) {
    console.log(data)
    return this.simple_get(`${API_VERSION_PREFIX}/drivers/`, data)
  }

  approveDriver(id: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/drivers/${id}/approve/`)
  }

  rejectDriver(id: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/drivers/${id}/reject/`)
  }

  suspendDriver(driverId: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/drivers/${driverId}/suspend/`)
  }

  approveRestaurant(id: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/restaurants/${id}/approve/`)
  }

  rejectRestaurant(id: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/restaurants/${id}/reject/`)
  }

  suspendRestaurant(driverId: number) {
    return this.simple_post(`${API_VERSION_PREFIX}/restaurants/${driverId}/suspend/`)
  }

  getOverview() {
    return this.simple_get(`${API_VERSION_PREFIX}/analytics/overview/`)
  }

  getUsersOverview(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/users/users_overview/`, data)
  }

  getOrdersOverview(data: any) {
    return this.simple_get(`${API_VERSION_PREFIX}/orders/orders_overview/`, data)
  }

  setConfig(data: any, id?: number) {
    return id
      ? this.simple_patch(`${API_VERSION_PREFIX}/order-config/${id}/`, data)
      : this.simple_post(`${API_VERSION_PREFIX}/order-config/`, data)
  }

  getConfig() {
    return this.simple_get(`${API_VERSION_PREFIX}/order-config/`)
  }

}
