import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import React, { useEffect, useState, useRef } from "react";
import { useApi } from "services/helpers";
import FormikInput from "components/FormikInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import pxToRem from "assets/theme/functions/pxToRem";

export const Configuration = () => {
  const api = useApi()
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);

  const [configValues, setConfigValues] = useState(null)

  const getConfig = () => {
    setLoading(true)
    // @ts-ignore
    api.getConfig().handle({
      errorMessage: "Error getting Configuration values",
      onSuccess: (response) => {
        if (response.kind === "ok") {
          setConfigValues(response.data)
        }
      },
      onFinally: () => setLoading(false)
    })
  }

  const setConfig = (data) => {
    setLoading(true)
    api.setConfig(data, configValues?.id).handle({
      onSuccess: (result) => {},
      errorMessage: "Error updating Configuration",
      successMessage: "Configuration has been updated",
      onFinally: () => setLoading(false)
    }
    )
  }

  const validationSchema =
    Yup.object().shape({
      mile_cost: Yup.string().required(),
      action_radius: Yup.string().required(),
      minimum_fee: Yup.string().required(),
    })

  const initialValues = {
    mile_cost: configValues?.mile_cost || 0,
    action_radius: configValues?.action_radius || 0,
    minimum_fee: configValues?.minimum_fee || 0,
  };

  useEffect(() => {
    getConfig()
  }, [])


  return (
    <AdminLayout title={'Configuration'}>
      <MDBox width={'100%'} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"} p={5}>


        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={values => setConfig(values)}
          enableReinitialize
        >
          {({ errors, isValid }) => (
            <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <FormikInput
                name={'mile_cost'}
                label={'Mile Cost'}
                type={'Mile Cost'}
                errors={errors}
                mb={2}
              />
              <FormikInput
                name={'action_radius'}
                label={'Action Radius'}
                type={'Action Radius'}
                errors={errors}
                mb={2}
              />
               <FormikInput
                name={'minimum_fee'}
                label={'Minimum Fee'}
                type={'Minimum Fee'}
                errors={errors}
                mb={2}
              />
              <MDBox textAlign="right">
                <MDTypography variant="button">
                </MDTypography>
              </MDBox>
              <MDBox mb={1} textAlign={"center"}>
                <MDButton
                  variant="contained"
                  color="primary"
                  loading={loading}
                  disabled={loading || !isValid}
                  sx={{ width: pxToRem(280) }}
                  type='submit'
                >
                  Save
                </MDButton>
              </MDBox>
            </Form>
          )}
        </Formik>


      </MDBox>
    </AdminLayout>
  );
}
const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 2
}
