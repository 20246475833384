import MDButton from "../../components/MDButton";
import React from "react";
import {photoRender} from "../../components/DataTableSimple/utils";
import {DRIVER_RESTAURANT_STATUS} from "../../services/helpers";

export const dataTableModel = {
  columns: [
    { Header: "Photo", accessor: "profile", disableOrdering: true },
    { Header: "Driver's name", accessor: "user__name" },
    { Header: "Email", accessor: "user__email" },
    { Header: "Suspend driver", accessor: "status", disableOrdering: true },
    { Header: "Order history", accessor: "order_history", disableOrdering: true },
    { Header: "Details", accessor: "detail", disableOrdering: true },
  ],
  rows: [],
};
export const rowRender = (data, setSuspendConfirm, setOpenDriverPhoto, setDriverPhoto, setShowDriverDetailsModal, setSelectedDriver) => {
  return data?.map((d) => {
    return {
      profile: photoRender(d?.user?.profile_picture, setOpenDriverPhoto, setDriverPhoto),
      user__name: d?.user?.name ?? " - ",
      user__email: d?.user?.email ?? " - ",
      status: <MDButton
        variant={d.status===DRIVER_RESTAURANT_STATUS.SUSPENDED ? 'contained' : 'outlined'}
        color={'primary'}
        onClick={() => {setSuspendConfirm(true); setSelectedDriver(d)}}
      >
        {d.status===DRIVER_RESTAURANT_STATUS.SUSPENDED ? 'Remove suspension' : 'Suspend'}
      </MDButton>,
      order: '-',
      detail: <MDButton
        variant={'contained'}
        color={'primary'}
        onClick={() => { setShowDriverDetailsModal(true); setSelectedDriver(d) }}
      >
        Details
      </MDButton>
    };
  });
}


